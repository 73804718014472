import React, { useEffect, useState } from 'react';
import PrivacySection from '../../components/PrivacySection/PrivacySection'
import privacyPolicyData from '../../constants/PrivacyPolicyDocument.json'
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
    const [privacyData, setPrivacyData] = useState([]);

    useEffect(() => {
        // Here, we simulate loading the data from the JSON file.
        setPrivacyData(privacyPolicyData);
    }, []);

    return (
        <div className="privacy-policy-container">
            {privacyData.map((section, index) => (
                <PrivacySection
                    key={index}
                    mainHeading={section.mainHeading}
                    mainInfo={section.mainInfo}
                    heading={section.heading}
                    subHeading={section.subHeading}
                    info={section.info}
                    tableData={section.tableData}
                />
            ))}
        </div>
    );
};

export default PrivacyPolicy;

import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web"; // Import react-spring for animations
import "./Hero.css";
import video from "../../../assets/videos/TPOEDCVideo.mp4"

const Hero = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  // Bounce-in animation for the heading
  const headingAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 170, friction: 26 },
    delay: 200, // Slight delay for staggered effect
  });

  // Fade-in animation for the description
  const descriptionAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 150, friction: 20 },
    delay: 300, // Description appears after heading
  });

  // Animation for video load-in
  const videoAnimation = useSpring({
    opacity: isVideoLoaded ? 1 : 0,
    transform: isVideoLoaded ? "translateY(0px)" : "translateY(50px)",
    config: { tension: 120, friction: 20 },
  });

  // Handle video load event
  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="hero">
      {/* Heading with animation */}
      <animated.div style={headingAnimation} className="hero-heading">
        <h1>
          EVERYDAY CREATORS, <br /> EVERYDAY CONTENT.
        </h1>
      </animated.div>

      {/* Description with animation */}
      <animated.p style={descriptionAnimation} className="hero-description">
        The key to winning with short-form video advertising—turning content
        challenges into opportunities for brands while driving meaningful social
        impact.
      </animated.p>

      {/* Video with animation */}
      <animated.div style={videoAnimation} className="hero-video-container">
        <video
          className="hero-video"
          onLoadedData={handleVideoLoad}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </animated.div>
    </div>
  );
};

export default Hero;

import React, { useState, useEffect } from 'react';
import { useTransition, animated, useSpring } from 'react-spring';
import video from "../../../assets/videos/TPOEDCVideo.mp4"
import './BrandHero.css';

const BrandHero = () => {
    const words = ["RESONATE", "ENGAGE", "INFLUENCE", "CONNECT"];
    const [index, setIndex] = useState(0);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 2000); // Change word every 2 seconds
        return () => clearInterval(interval);
    }, [words.length]);

    // Animation for text transition
    const transitions = useTransition(words[index], {
        key: index,
        from: { opacity: 0, transform: 'translateY(-80px)' },
        enter: { opacity: 1, transform: 'translateY(0px)' },
        leave: { opacity: 0, transform: 'translateY(80px)' },
        config: { tension: 250, friction: 20 },
    });

    // Bounce-in load animation for the main text
    const bounceIn = useSpring({
        from: { opacity: 0, transform: 'translateY(-50px)' },
        to: { opacity: 1, transform: 'translateY(0px)' },
        config: { tension: 150, friction: 25 },
    });

    // Animation for video load-in
    const videoAnimation = useSpring({
        opacity: isVideoLoaded ? 1 : 0,
        transform: isVideoLoaded ? 'translateY(0px)' : 'translateY(50px)',
        config: { tension: 120, friction: 20 },
    });

    // Handle video load event
    const handleVideoLoad = () => {
        setIsVideoLoaded(true);
    };

    return (
        <div className="brand-hero">
            <div className="brand-hero-overlay">
                {/* Apply bounce-in animation to the main text */}
                <animated.div style={bounceIn} className="brand-hero-text">
                    <h1>
                        <span className="first-line">THROUGH EVERYDAY CONTENT, BRANDS </span>
                        <span className="second-line">DON'T JUST REACH CONSUMERS - THEY</span>
                    </h1>
                    <div className="changing-word-container">
                        {transitions((style, word) => (
                            <animated.span
                                style={style}
                                className="changing-word"
                            >
                                {word}
                            </animated.span>
                        ))}
                    </div>
                </animated.div>

                <animated.div style={videoAnimation} className="brand-hero-video-container">
                    <video
                        className="brand-hero-video"
                        onLoadedData={handleVideoLoad}
                        autoPlay
                        loop
                        muted
                        playsInline
                    >
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </animated.div>
            </div>

        </div>
    );
};

export default BrandHero;

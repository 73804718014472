import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Home from '../components/Home/Home/Home';
import LetsTalk from './LetsTalk/LetsTalk';
import ForBrands from './ForBrands/ForBrands';
import OurPurpose from './OurPurpose/OurPurpose';
import ForCreators from './ForCreators/ForCreators';
import Footer from "../components/Footer/Footer";
import PrivacyPolocy from "./PrivacyPolicy/PrivacyPolicy"
import OurNews from './OurNews/OurNews'
import './App.css';
import { TermsAndConditions } from '../components/termsAndConditions';

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTopOnRouteChange /> {/* Triggers scroll to top on route change */}
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/for-creators" element={<ForCreators />} />
          <Route path="/for-brands" element={<ForBrands />} />
          <Route path="/our-purpose" element={<OurPurpose />} />
          <Route path="/lets-talk" element={<LetsTalk />} />
          <Route path="/our-news" element={<OurNews />} />
          <Route path="/privacy-policy" element={<PrivacyPolocy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>
        <div className='footer-app'>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
import React from 'react';
import ContainerBox from '../ContainerBox/ContainerBox';
import './ContentEquation.css';
import { useSpring, animated } from 'react-spring'; // Import react-spring for animations
import { useInView } from 'react-intersection-observer'; // Import useInView for scroll detection

const ContentEquation = () => {
    // Scroll trigger setup
    const { ref, inView } = useInView({
        triggerOnce: true, // Animation triggers only once
        threshold: 0.1, // Trigger animation when 10% of the component is visible
    });

    // Animation configuration for the text
    const bounceAnimation = useSpring({
        transform: inView ? "scale(1)" : "scale(0.8)",
        opacity: inView ? 1 : 0,
        config: { tension: 170, friction: 12, mass: 1 },
    });

    return (
        <ContainerBox>
            <animated.div ref={ref} style={bounceAnimation} className="container-content">
                <div className="content-equation">
                    <div className="content-item">THE CONTENT CONSUMERS CRAVE</div>
                    <div className="content-symbol">+</div>
                    <div className="content-item">THE CONTENT ALGORITHMS DEMAND</div>
                    <div className="content-symbol">=</div>
                    <div className="content-item">WINNING IN THE SHORT-FORM ADVERTISING ERA</div>
                </div>
            </animated.div>
        </ContainerBox>
    );
};

export default ContentEquation;

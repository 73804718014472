import React, { useEffect } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { useSpring, animated } from '@react-spring/web';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './LetsTalk.css';

const LetsTalk = () => {
    // Adjust the bounce-in animation to slow it down
    const bounceIn = useSpring({
        from: { opacity: 0, transform: 'translateY(-50px)' }, // Initial state
        to: { opacity: 1, transform: 'translateY(0px)' },     // Final state
        config: { tension: 150, friction: 25 },               // Slower settings
    });

    return (
        <animated.div style={bounceIn} className="lets-talk-container">
            <div className="contact-info-container">
                <div className="contact-info">
                    <h1 className="lets-talk-heading">LET'S CONNECT</h1>
                    <p className="contact-email">hello@edcsquared.io</p>
                    <p className="contact-description">Let's connect! Whether you're a brand, creator, or partner, we'd love to hear from you. We're always up for a chat—be it virtual or over a coffee in person. Reach out, and let's explore how we can create something extraordinary together.</p>
                </div>
            </div>
            <Form />
        </animated.div>
    );
};

const Form = () => {
    const [state, handleSubmit] = useForm("xqakolwv");

    useEffect(() => {
        if (state.errors) {
            toast.error("Sorry, there was a problem! Please contact us at hello@edcsquared.io", {
                position: "bottom-right",
                className: 'notification left-aligned',
            });
        }
    }, [state.errors]);

    if (state.succeeded) {
        return <p className="contact-description">Thank you! Your message has been sent.</p>
    }

    return (
        <div className="form-content">
            <form onSubmit={handleSubmit} method="POST" action="https://formspree.io/f/xqakolwv">
                <div className="form-group">
                    <input
                        className="input styled-input"
                        placeholder=" "
                        type="text"
                        id="name"
                        name="name"
                        required
                    />
                    <label className="floating-label" htmlFor="name">Name</label>
                    <ValidationError prefix="Name" field="name" errors={state.errors} />
                </div>
                <div className="form-group">
                    <input
                        className="input styled-input"
                        placeholder=" "
                        type="email"
                        id="email"
                        name="email"
                        required
                    />
                    <label className="floating-label" htmlFor="email">E-mail Address</label>
                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                </div>
                <div className="form-group">
                    <textarea
                        className="input styled-input"
                        placeholder=" "
                        id="message"
                        name="message"
                        rows="6"
                        required
                    />
                    <label className="floating-label" htmlFor="message">Message</label>
                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                </div>
                <div className="form-button-container">
                    <button className="form-button" type="submit" disabled={state.submitting}>
                        {state.submitting ? (
                            <span className="button-loader">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="spinner"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    style={{ width: '16px', height: '16px', marginRight: '8px' }}
                                >
                                    <circle cx="12" cy="12" r="10" className="spinner-circle" />
                                    <path d="M12 2v4" />
                                    <path d="M12 18v4" />
                                    <path d="M4.93 4.93l2.83 2.83" />
                                    <path d="M16.24 16.24l2.83 2.83" />
                                    <path d="M2 12h4" />
                                    <path d="M18 12h4" />
                                    <path d="M4.93 19.07l2.83-2.83" />
                                    <path d="M16.24 7.76l2.83-2.83" />
                                </svg>
                                Processing...
                            </span>
                        ) : (
                            'SEND'
                        )}
                    </button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    );
};

export default LetsTalk;

import React from 'react';
import './PrivacySection.css';

const PrivacySection = ({ heading, subHeading, info, mainHeading, mainInfo, tableData }) => {
    const processText = (text, boldFirstWord) => {
        if (typeof text !== 'string') return text;

        if (boldFirstWord) {
            const words = text.split(' ');
            if (words.length > 0) {
                words[0] = `<strong>${words[0]}</strong>`;
            }
            text = words.join(' ');
        }

        const linkRegex = /\[([^\]]+)]\((https?:\/\/[^\s]+)\)/g;
        text = text.replace(linkRegex, (_, linkText, linkUrl) => {
            return `<a href="${linkUrl}" target="_blank" rel="noopener noreferrer">${linkText}</a>`;
        });

        const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
        text = text.replace(emailRegex, (email) => {
            return `<a href="mailto:${email}">${email}</a>`;
        });

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        text = text.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });

        return <span dangerouslySetInnerHTML={{ __html: text }} />;
    };

    return (
        <div className="privacy-section">
            {mainHeading && (
                <>
                    <h1 className="privacy-main-heading">{mainHeading}</h1>
                    <div className="privacy-main-info">{processText(mainInfo, false)}</div>
                </>
            )}
            {heading && <h2 className="privacy-heading">{heading}</h2>}
            {subHeading && <h3 className="privacy-subheading">{subHeading}</h3>}
            {info && Array.isArray(info) ? (
                info.map((paragraph, index) => (
                    <div key={index} className="privacy-info">
                        {typeof paragraph === 'string' ? (
                            processText(paragraph, false)
                        ) : (
                            <>
                                {paragraph.subHeading && <h4 className="privacy-sub-info-heading">{paragraph.subHeading}</h4>}
                                {Array.isArray(paragraph.content) ? (
                                    paragraph.content.map((line, lineIndex) => (
                                        <div key={lineIndex}>{processText(line, paragraph.boldFirstWord)}</div>
                                    ))
                                ) : (
                                    <div>{processText(paragraph.content, paragraph.boldFirstWord)}</div>
                                )}
                            </>
                        )}
                    </div>
                ))
            ) : (
                <div className="privacy-info">{processText(info, false)}</div>
            )}
            {tableData && (
                <div className="privacy-table-container">
                    <table className="privacy-table">
                        <thead>
                            <tr>
                                {tableData.headers.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{processText(cell, false)}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default PrivacySection;

import React from 'react';
import { useSpring, animated } from 'react-spring';
import './OurNews.css';

const ComingSoon = () => {
    // Load-in animation for the text
    const fadeIn = useSpring({
        from: { opacity: 0, transform: 'translateY(-50px)' },
        to: { opacity: 1, transform: 'translateY(0px)' },
        config: { tension: 170, friction: 20 },

    });

    return (
        <animated.div style={fadeIn} className="coming-soon-container">
            <h1 className="coming-soon-text">Coming Soon</h1>
        </animated.div>
    );
};

export default ComingSoon;

import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './MessageBox.css';

const MessageBox = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const bounceAnimation = useSpring({
        transform: inView ? 'scale(1)' : 'scale(0.8)',
        opacity: inView ? 1 : 0,
        config: { tension: 170, friction: 12, mass: 1 },
    });

    return (
        <animated.div
            ref={ref}
            style={bounceAnimation}
            className="message-box-container-for-brands"
        >
            <p className="message-text-for-brands">
                Looking for authentic, high-quality organic content? In addition to our innovative paid media solution, our <span className="highlight-for-brands">Everyday Content</span> packages offer brands the opportunity to connect with audiences in a genuine, relatable way. Get in touch to learn more about how we can help your brand create meaningful connections.
            </p>
        </animated.div>
    );
};

export default MessageBox;